import { Placement, computePosition, flip, offset as offsetFn, shift, autoUpdate } from '@floating-ui/dom'

type FloatingUIOptions = {
	isHidden?: boolean
	offset?: number
	padding?: number
	placement?: Placement
}
export function useFloatingUI(referenceRef: Ref, floatingRef: Ref, options: FloatingUIOptions = {}) {
	const { isHidden = true, offset = 8, padding = 8, placement = 'bottom' } = options ?? {}
	const isFloatingElementHidden = ref(isHidden)

	const calculatePosition = async () => {
		const { x, y } = await computePosition(referenceRef.value, floatingRef.value, {
			placement,
			middleware: [offsetFn(offset), flip(), shift({ padding })],
		})

		Object.assign(floatingRef.value.style, { left: `${x}px`, top: `${y}px` })
	}

	let cleanup: any
	const toggleFloatingElement = () => {
		isFloatingElementHidden.value = !isFloatingElementHidden.value

		if (!isFloatingElementHidden.value) {
			calculatePosition()
			cleanup = autoUpdate(referenceRef.value, floatingRef.value, calculatePosition)
		} else {
			cleanup()
		}
	}

	return {
		isFloatingElementHidden,
		toggleFloatingElement,
	}
}
